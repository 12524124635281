import React from 'react'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ConfedLogoLarge from '../../content/assets/confed-logo-2019-large.svg'
import ConfedLogoMD from '../../content/assets/confed-logo-2019-medium.svg'
import ConfedLogoSM from '../../content/assets/confed-logo-2019-small.svg'
import ConfedLogoXSM from '../../content/assets/confed-logo-2019-xsmall.svg'
import ConfedLogoTY from '../../content/assets/confed-logo-2019-logotype.svg'

import Headerjawn from '../components/headerjawn'
import Layout from '../components/layout'
import Footerjawn from '../components/footerjawn'


import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { tween } from 'popmotion';


const Box = posed.div({
  hidden: { 
    opacity: [0,1], 
    left: '-100%',
    transition: props => tween({ ...props, duration: 0 })
  },
  visible: { 
    opacity: [1,0], 
    left: '100%',
    transition: props => tween({ ...props, duration: 1000 })
  },
})

export default (props) => (
  <>
  	<Headerjawn/>
      <Layout>

        <section className="home-cc">
        <h2>Home</h2>

        <ConfedLogoLarge style={{ maxWidth: `500px` }} />
        <ConfedLogoMD style={{ maxWidth: `500px` }} />
        <ConfedLogoSM style={{ maxWidth: `300px` }}/>
        <ConfedLogoXSM style={{ maxWidth: `100px` }}/>
        <ConfedLogoTY style={{ maxWidth: `200px` }} />

        <p>TODO</p>
        <aside className="testimgwrap">
        <Img fluid={props.data.imageOne.childImageSharp.fluid} />
        <Img fluid={props.data.imageTwo.childImageSharp.fluid} />
        <Img fluid={props.data.imageThree.childImageSharp.fluid} />
        </aside>
        <h2>SKUNK WORKS</h2>
        <p>This is just a site under construction- created with Gatsby, Webpack, React, and a few other goodies.</p>
        
        <p>What a world.</p>
        
        </section>
      </Layout>

      <Footerjawn/>

      <TransitionState>
          {({ transitionStatus }) => {
              return (
                  <Box
                    className="box"
                    pose={
                      ['entering', 'entered'].includes(transitionStatus)
                        ? 'hidden'
                        : 'visible'
                    }
                  />
              )
          }}
      </TransitionState>
      <style
            dangerouslySetInnerHTML={{
              __html: `
              .box {
                  background: #774532;
              }
          `,
            }}
      />
      
  </>
)


export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "one.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "two.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "three.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`